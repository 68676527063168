@import url("https://fonts.googleapis.com/css2?family=Satoshi&display=swap");

body {
  margin: 0;
  font-family: "Satoshi" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: auto;
  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

@font-face {
  font-family: "Satoshi";
  src: local("Satoshi-Regular"),
    url("../src/assets/fonts/Satoshi-Regular.otf") format("truetype");
}

@font-face {
  font-family: "Satoshi-Bold";
  src: local("Satoshi-Bold"),
    url("../src/assets/fonts/Satoshi-Bold.otf") format("truetype");
}

code {
  font-family: "Satoshi !important";
}

.MuiTypography-root {
  font-family: "Satoshi !important";
}
